var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"page-content"},[_vm._m(0),(_vm.loading)?_c('div',{staticClass:"overlay d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"d-flex justify-content-center mb-2"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1)]):_vm._e(),_c('div',{staticClass:"page-body"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"table-responsive shadow horizontal-bar"},[_c('table',{staticClass:"table pretty-title-gray mb-0"},[_c('thead',[_c('tr',[_c('th',{staticClass:"table-pretty dbo-ui col-5 col-sm-2 min-width-integations-table"}),_vm._l((_vm.fields),function(field){return _c('th',{key:field,staticClass:"table-pretty dbo-ui width-integations-table"},[_c('div',{staticClass:"wrapper-header text-center mx-auto"},[_vm._v(" "+_vm._s(field)+" ")])])})],2)]),_c('tbody',_vm._l((_vm.insuranceCategoryTypes),function(insuranceCategoryType){return _c('tr',{key:insuranceCategoryType},[_c('td',{staticClass:"font-12 cell-border my-5 width-integrations-items align-middle"},[_vm._v(" "+_vm._s(_vm.getEnumKeyByEnumValue( _vm.translateInsuranceCategoryTypes, insuranceCategoryType ))+" ")]),_vm._l((_vm.fields),function(field){return _c('td',{key:field,staticClass:"cell-integration cell-nao-integrado"},_vm._l((_vm.insurerGuaranteeIntegrations),function(insurerGuaranteeIntegration){return _c('div',{key:insurerGuaranteeIntegration.id,staticClass:"integration-fields"},[(
                          insurerGuaranteeIntegration.name == field &&
                          insurerGuaranteeIntegration.insurance_category_id ==
                            insuranceCategoryType
                        )?_c('td',{class:{
                          'cell-integrado':
                            insurerGuaranteeIntegration.insurance_category_id ==
                            insuranceCategoryType,
                          'd-flex align-items-center justify-content-center':
                            insurerGuaranteeIntegration.limit <= 0,
                        },on:{"click":function($event){_vm.showModalIntegration(
                            insuranceCategoryType,
                            _vm.getEnumKeyByEnumValue(
                              _vm.translateInsurerIdentifierTypes,
                              field
                            )
                          )}}},[_c('i',{staticClass:"fa fa fa-check"}),(insurerGuaranteeIntegration.limit > 0)?_c('p',{staticClass:"font-12"},[_vm._v(" "+_vm._s(_vm.formatCurrency(insurerGuaranteeIntegration.limit))+" ")]):_vm._e()]):_vm._e()])}),0)})],2)}),0)])])])])])]),_c('div',{staticClass:"modal"},[_c('b-modal',{ref:"showModalIntegration",staticClass:"mt-4",attrs:{"id":"show-modal-integration","size":"lg","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h5',{staticClass:"modal-title"},[_vm._v("Alterar integração")])]},proxy:true}])},[_c('br'),_c('div',{staticClass:"row form"},[_c('div',{staticClass:"col-12 col-lg-auto pr-lg-4 mb-3 ml-3"},[_c('label',{staticClass:"dbo-ui mb-3",attrs:{"for":""}},[_vm._v("Seguradora")]),_c('img',{staticClass:"d-block mb-3 width-integrations-items",attrs:{"src":_vm.data.icon,"alt":""}})]),_c('div',{staticClass:"col pl-lg-4 border-left"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-3"},[_c('label',{staticClass:"dbo-ui",attrs:{"for":""}},[_vm._v("Tipo de seguro")]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(this.data.insuranceCategoryType))])]),_c('div',{staticClass:"col-12 mb-3"},[_c('label',{staticClass:"dbo-ui",attrs:{"for":""}},[_vm._v("Limite")]),_c('money',_vm._b({staticClass:"col-12 form-control",attrs:{"placeholder":"Digite o valor do limite"},model:{value:(_vm.data.limit),callback:function ($$v) {_vm.$set(_vm.data, "limit", $$v)},expression:"data.limit"}},'money',_vm.money,false))],1)])])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.saveIntegrationData()}}},[_vm._v(" Alterar ")])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header"},[_c('div',{staticClass:"row space-around mb-3 d-flex"},[_c('div',{staticClass:"col-3"},[_c('h1',{staticClass:"text-white"},[_c('i',{staticClass:"mdi mdi-link-variant"}),_vm._v(" Integrações")])])])])
}]

export { render, staticRenderFns }