<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="row space-around mb-3">
          <div class="col-10">
            <h1 class="text-white">Certidões</h1>
          </div>
        </div>
      </div>

      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>

      <div class="page-body">
        <div class="card">
          <div class="card-body p-0">
            <div class="table-responsive shadow horizontal-bar">
              <b-table
                class="table pretty-title-gray mb-0"
                :items="items"
                :fields="fields"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template
                  class="table-td cursor-default text-center nowrap"
                  #cell(action)="data"
                >
                  <span
                    @click="
                      redirect('edit-certificate', { identifier: data.value })
                    "
                    class="text-secondary"
                    v-b-tooltip.hover
                    title="Editar"
                  >
                    <i class="fas fa-pen mx-1 icon-btn"></i>
                  </span>
                  <span
                    @click="
                      redirect('details-certificate', {
                        identifier: data.value,
                      })
                    "
                    class="text-secondary"
                    v-b-tooltip.hover
                    title="Detalhes"
                  >
                    <i class="fas fa-eye mx-1 icon-btn"></i>
                  </span>
                </template>
              </b-table>
            </div>
            <nav aria-label="Paginação" class="m-3">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonsService from "@/services/commons";
import dateFormatter from "@/mixins/dateFormatter";
import translateInsuranceTypes from "@/enums/translate/insuranceTypes";
import commomsHelper from "@/mixins/commomsHelper";

export default {
  name: "list-certificates",
  mixins: [dateFormatter, commomsHelper],
  data: () => ({
    perPage: 10,
    currentPage: 1,
    translateInsuranceTypes: translateInsuranceTypes,
    fields: [
      {
        key: "identifier",
        label: "IDENTIFICADOR",
        tdClass: "column-size-share",
        thClass: "table-pretty dbo-ui rounded-top",
      },
      { key: "name", label: "NOME", tdClass: "font-14", thClass: "table-pretty dbo-ui", },
      { key: "action", label: "AÇÃO", tdClass: "column-size-share font-12", thClass: "table-pretty dbo-ui rounded-top", },
    ],
    items: [],
    filter: null,
  }),
  created() {
    this.getCertificates();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    getCertificates() {
      this.resetAlert();
      commonsService
        .insurers({has_integration: true})
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.formatList(response.data);
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as certidões.");
        });
    },
    formatList(data) {
      var insurerFormat = [];
      Object.keys(data).forEach((item) => {
        insurerFormat.push({
          identifier: item,
          name: data[item],
          action: item,
        });
      });
      this.items = insurerFormat;
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
  },
};
</script>

<style>
</style>
